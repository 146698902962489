<template>
    <div class="CEESAR-Table">
        <div class="CEESAR-TableHeader" v-if="hasHeader">
            <slot name="Header"/>
        </div>

        <div class="CEESAR-TableFiltersContainer" v-if="hasFilter">
          <MaterialDesign-Icon icon="filter_list" size="24"/>
          <div class="CEESAR-TableFilters">
            <slot name="Filters"/>
          </div>
        </div>

        <div class="CEESAR-TableToolsContainer" v-if="hasTools">
          <MaterialDesign-Icon icon="sort" size="24"/>
          <div class="CEESAR-TableTools">
            <slot name="Tools"/>
          </div>
        </div>

        <div class="CEESAR-TableContent">
          <div class="CEESAR-TableContentLines">
            <slot />
          </div>
        </div>

        <div class="CEESAR-TableButtons" v-if="hasButton">
            <slot name="Buttons"/>
        </div>

        <div class="CEESAR-TableFooter" />
    </div>
</template>

<script>
export default {
    name: "CEESAR-Table",
    mounted(){
      this.$nextTick(() => this.arrangeFilterLabels());
    },
    beforeUpdate(){
      this.arrangeFilterLabels();
    },
    methods:{
      arrangeFilterLabels()
      {
        let container = this.$el.getElementsByClassName("CEESAR-TableFilters");
        let labels = container[0].getElementsByClassName("CEESAR-AlignLabel");
        let largestWidth = 0;
        labels.forEach(label => {
          label.style.width = "";
          let labelWidth = label.getBoundingClientRect().width;
          if(largestWidth < labelWidth) largestWidth = label.offsetWidth;
        });
        
        labels.forEach(label => {
          label.style.minWidth = largestWidth + "px";
        });
      }
    },
    computed: {
    hasHeader() {
      return !!this.$slots['Header']
    },
    hasFilter() {
      return !!this.$slots['Filters']
    },
    hasTools() {
      return !!this.$slots['Tools']
    },
    hasButton() {
      return !!this.$slots['Buttons']
    }
  }
}
</script>

<style>
</style>